<template>
  <Page :title="$t('widgets.loyalty.header.mainText')">
    <div class="l-loyalty">
      <BannerImageAndTitle
        :background="[
          {
            url: $t('widgets.loyalty.header.background[0].url'),
            format: $t('widgets.loyalty.header.background[0].format'),
            altText: $t('widgets.loyalty.header.background[0].altText'),
          },
          {
            url: $t('widgets.loyalty.header.background[1].url'),
            format: $t('widgets.loyalty.header.background[1].format'),
          },
        ]"
        :banner-title="$t('widgets.loyalty.header.mainText')"
      />
      <SectionContainer
        size="md"
        class="h-pv-20"
      >
        <Typography
          class="l-loyalty__caption"
          v-html="$t('widgets.loyalty.contentBody.headText')"
          variant="subtitle1"
        />
        <Typography
          v-text="$t('widgets.loyalty.contentBody.subText', { points: fidelityPoints })"
          variant="body1"
          class="h-mb-20"
        />
        <Carousel
          :settings="settings"
          :breakpoints="breakpoints"
        >
          <Slide
            v-for="{id, points, text} in customerDiscounts"
            :key="id"
            class="l-loyalty__loyaltySlide"
          >
            <LoyaltyDonut
              :progress="fidelityPoints"
              :total="Number(points)"
              :promo-text="text"
            />
          </Slide>

          <template #addons>
            <CarouselNavigation />
          </template>
        </Carousel>
      </SectionContainer>
    </div>
  </Page>
</template>

<script setup>
import { computed, markRaw, ref } from 'vue';
import { Carousel, Slide } from 'vue3-carousel';
import { useStore } from 'vuex';

import { apiServices } from '@/api/services';
import LoyaltyDonut from '@/components/LoyaltyDonut.vue';
import Page from '@/components/Page.vue';
import BannerImageAndTitle from '@/components/banner/BannerImageAndTitle.vue';
import CarouselNavigation from '@/components/common/CarouselNavigation.vue';

const settings = markRaw({
  itemsToShow: 1,
  snapAlign: 'center',
});
const breakpoints = markRaw({
  640: {
    itemsToShow: 2,
    snapAlign: 'start',
  },
  1024: {
    itemsToShow: 3,
    snapAlign: 'start',
  },
  1280: {
    itemsToShow: 4,
    snapAlign: 'start',
  },
});

const store = useStore();
const customerDiscounts = ref(null);

async function getCustomerDiscounts() {
  customerDiscounts.value = await apiServices.getCustomerDiscounts();
}

getCustomerDiscounts();
const fidelityPoints = computed(() => store.state.auth.user.fidelity_points);

</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;

.l-loyalty {
  &__caption {
    &::v-deep(span) {
      @include mx.use-color('primary');
    }
  }

  &__loyaltySlide {
    width: 100%;
    padding: 8px;
  }
}

</style>
