<template>
  <div class="c-loyaltyCard">
    <small v-text="promoText.split(' ').shift()" />
    <Typography
      v-text="props.promoText"
      :class="['-fontBold', {'h-mb-20': isCompleted}]"
      variant="subtitle2"
      is-dark-theme
    />
    <p v-if="!isCompleted">
      <small
        v-text="$t('widgets.loyalty.loyaltyDonut.remainingText',
                   { points: props.total - props.progress })"
        class="-darkTheme h-uppercase h-pb-20"
      />
    </p>
    <vc-donut
      class="c-loyaltyCard__donut"
      :sections="[
        {
          value: isCompleted ? 100 : percentageProgress,
          color: isCompleted ? 'green' : 'red'
        }
      ]"
      :start-angle="180"
      :size="100"
      unit="%"
      :thickness="5"
      background="#222222"
      foreground="rgba(255, 255, 255, 0.2)"
    >
      <Typography
        variant="subtitle3"
        v-html="`${total}<br>Pt`"
      />
    </vc-donut>
    <Typography
      v-if="isCompleted"
      class="c-loyaltyCard__bottomTxt"
      v-text="$t('widgets.loyalty.loyaltyDonut.claimText')"
      variant="body1"
    />
  </div>
</template>

<script setup>
import Typography from '@/components/common/Typography.vue';

const props = defineProps({
  progress: {
    type: Number,
    required: true,
  },
  total: {
    type: Number,
    required: true,
  },
  promoText: {
    type: String,
    required: true,
  },
});

const percentageProgress = (props.progress / props.total) * 100;
const isCompleted = percentageProgress > 100;

</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;
@use "src/assets/styles/abstracts/variables" as v;

.c-loyaltyCard {
  @include mx.d-flex('flex-start', 'center', true);

  overflow: hidden;

  min-width: 270px;
  max-width: 330px;
  min-height: 450px;
  padding: 20px;

  text-align: center;

  color: white;
  border-radius: v.$base-border-radius;
  background-color: #222222;
  box-shadow: v.$base-box-shadow;

  @include mx.bp('phone-md') {
    min-width: 320px;
  }

  @include mx.bp('tablet') {
    min-width: 290px;
  }

  @include mx.bp('desktop-wide') {
    min-width: 276px;
  }

  &__donut {
    width: 100%;
    max-width: 210px;
    margin: 20px auto;
  }

  &__bottomTxt {
    margin-top: auto;
  }
}
</style>
