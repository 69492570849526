<template>
  <section class="l-banner">
    <ResponsiveImage
      :style="'height: 100%'"
      class="l-banner__img"
      :images="props.background"
    />
    <SectionContainer
      bg-color="inherit"
      size="md"
    >
      <div class="l-banner__title">
        <BaseButton
          icon="prev"
          icon-width="18"
          v-if="props.navigateBack"
          color="lightColor"
          class="h-pr-20"
          @click="router.go(-1)"
        />
        <Typography
          v-text="props.bannerTitle"
          variant="h1"
          is-dark-theme
        />
      </div>
      <Typography
        v-text="props.bannerSubTitle"
        variant="subtitle2"
        v-if="props.bannerSubTitle"
        is-dark-theme
        class="-fontLight h-m-0"
      />
    </SectionContainer>
  </section>
</template>

<script setup>
import { computed, inject } from 'vue';
import { useRouter } from 'vue-router';

import ResponsiveImage from '@/components/common/ResponsiveImage.vue';

const props = defineProps({
  bannerTitle: { type: String, required: true },
  bannerSubTitle: { type: String, default: '' },
  background: { type: Array, required: true },
  navigateBack: { type: Boolean },
});
const isMobile = inject('isMobile');
const router = useRouter();
const height = computed(() => (isMobile.value ? '190px' : '300px'));

</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;

.l-banner {
  position: relative;

  width: 100%;
  // this error will be fix soon on WebStorm https://youtrack.jetbrains.com/issue/WEB-52425
  height: v-bind(height);

  &__img {
    position: relative;
  }

  &::v-deep(.l-container) {
    position: absolute;
    bottom: 0;

    width: 100%;

    @include mx.bp('tablet-wide') {
      bottom: 30px;
    }
  }

  &__title {
    @include mx.d-flex();
  }
}
</style>
